<template>
    <div>
        <div class="title">{{ title }}</div>
        <div class="inspection-feedback-content" v-for="(item,index) in content" :key="index">
            <div class="a-flex-rfsfs">
                <span class="inspection-feedback-content-title">{{ index+1 }}. {{ item.question || '-' }}</span>
                <div class="a-flex-rfsfs inspection-feedback-content-answer" v-if="item.questionType == 1 || item.questionType == 2">
                    <div class="a-flex-rfsc a-ml-20 a-c-blue" v-for="(ite,idx) in item.answerItems" :key="idx">
                        <i class="el-icon-success"></i>
                        <span>{{ ite.chooseItemName }}</span>
                    </div>
                </div>
            </div>
            <div class="a-flex-cfsfs a-ml-20" v-if="item.questionType == 3">
                <div class="inspection-feedback-content-rmark ">
                    <span class="a-c-666">巡检反馈：</span>
                    <span class="a-c-blue">{{ item.answerText || '无' }}</span>
                </div>
            </div>
            <div class="inspection-feedback-content-rmark">
                <div class="inspection-feedback-content-rmark ">
                    <span class="a-c-666">巡检备注：</span>
                    <span class="a-c-blue">{{ item.checkText || '无' }}</span>
                </div>
            </div>
            <div class="inspection-feedback-content-rmark">
                <span class="a-c-666">巡检图片：</span>
                <div class="a-flex-rfsfs a-flex-wrap a-pb-10">
                    <el-image 
                        v-for="(ite,idx) in item.checkImages"
                        :key="idx"
                        style="width: 80px; height: 80px; border-radius: 8px; margin: 10px 10px 0 0;border: 1px solid #333333"
                        :src="ite" 
                        :preview-src-list="item.checkImages">
                    </el-image>
                </div>
            </div>
            
            <el-table 
                v-if="item.selectTableData && item.selectTableData.length"
                :border='true' 
                :data="item.selectTableData" 
                style="width: 100%;" >
                <el-table-column prop="orderId" :label="ite" min-width="120" v-for="(ite,idx) in item.selectTableDataHead" :key="idx">
                    <template slot-scope="{ row }" >
                        <el-image 
                            v-if="isImgs(row.answerItems[0].answerText)"
                            style="width: 80px; height: 80px; border-radius: 8px; margin: 10px 10px 0 0;border: 1px solid #333333"
                            :src="row.answerItems[0].answerText" 
                            :preview-src-list="[row.answerItems[0].answerText]">
                        </el-image>
                        <span v-else>{{ row.answerItems[0].answerText || "-" }}</span>
                    </template>
                </el-table-column>
            </el-table>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
            };
        },
        props: {
            title: {
                default: ''
            },
            content: {
                default: ()=>{
                    return []
                }
            }
        },
        methods:{
            isImgs (url) {
                let regs = /^https?:\/\/[^\s/$.?#].[^\s]*\.(gif|jpg|jpeg|png|bmp|tiff)$/i
                return regs.test(url)
            }
        }
    }
</script>

<style lang="scss" scoped>
    .title{
        font-size: 14px;
        color: #007af0;
        font-weight: 700;
        padding-bottom: 10px;
    }
    .inspection-feedback-content{
        margin-bottom: 12px;
        font-size: 13px;
        .inspection-feedback-content-title{
            color: #333333;
            font-weight: 700;
        }
        .inspection-feedback-content-answer{
            // margin: 12px 0;
        }
        .inspection-feedback-content-rmark{
            margin-top: 12px;
        }
        /deep/ .el-table__header tr{
            background: #ffffff !important;
        }
        /deep/ .el-table__header th.el-table__cell{
            background: #ffffff !important;
        }
    }
</style>