<template>
    <div class="app-body">
		<div class="a-flex-rsbc">
            <bread-crumb></bread-crumb>
            <el-dropdown placement="bottom-start" @command="handlerOperation">
                <el-button type='primary'>操作</el-button>
                <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item 
                        :command='"gq"' 
                        :disabled="!((workOrderInfo.status == 1 || workOrderInfo.status == 2 || workOrderInfo.status == 4 || workOrderInfo.status == 6)
                        && (userInfo.dataPermission == 99 || userInfo.dataPermission == 88))"
                        >
                        挂起
                    </el-dropdown-item>
                    <el-dropdown-item :command='"jcgq"' 
                        :disabled="!(workOrderInfo.status == 7 && (userInfo.dataPermission == 99 || userInfo.dataPermission == 88))"
                        >解除挂起</el-dropdown-item>
                    <el-dropdown-item :command='"bgzxr"'
                        :disabled="!((workOrderInfo.status != 3 && workOrderInfo.status != 5)
                        && (userInfo.dataPermission == 99 || userInfo.dataPermission == 88))"
                        >变更执行人</el-dropdown-item>
                    <el-dropdown-item :command='"yq"'
                        :disabled="!(workOrderInfo.status != 3 && workOrderInfo.status != 5 && (userInfo.dataPermission == 99 || userInfo.dataPermission == 88))"
                        >延期</el-dropdown-item>
                    <el-dropdown-item :command='"tjms"' :disabled="!(workOrderInfo.status != 3 && workOrderInfo.status != 5)">添加描述</el-dropdown-item>
                    <el-dropdown-item 
                        :command='"zycd"' 
                        :disabled="!((workOrderInfo.status != 3 && workOrderInfo.status != 5)
                        && (userInfo.dataPermission == 99 || userInfo.dataPermission == 88))">重要程度</el-dropdown-item>
                    <el-dropdown-item 
                        :command='"wcgd"' 
                        :disabled="!(workOrderInfo.status != 3 && workOrderInfo.status != 5 && workOrderInfo.status != 7 && userInfo.dataPermission == 99)">完成工单</el-dropdown-item>
                </el-dropdown-menu>
            </el-dropdown>
        </div>
        <div class="main a-flex-cfsfs">
            <div class="a-w-100 main-card">
                <div class="a-pb-20">
                    <span class="a-fw-700">No.{{ workOrderInfo ? workOrderInfo.workOrderSn : '' }} {{ workOrderInfo ? workOrderInfo.title : '' }}</span>
                </div>
                <div class="a-flex-rfsfs a-flex-wrap" v-if="workOrderInfo">
                    <div class="list-item">
                        <span class="list-item-title">处理类型</span>
                        <span class="list-item-value">{{ workOrderInfo.solveType | initDic(solveTypeDic) }}</span>
                    </div>
                    <div class="list-item">
                        <span class="list-item-title">站点类型</span>
                        <span class="list-item-value">{{ workOrderInfo.stationType | initDic(stationTypeDic) }}</span>
                    </div>
                    <div class="list-item">
                        <span class="list-item-title">发布时间</span>
                        <span class="list-item-value">{{ workOrderInfo.sendTime || "-" }}</span>
                    </div>
                    <div class="list-item">
                        <span class="list-item-title">完成时间</span>
                        <span class="list-item-value">{{ workOrderInfo.solveTime || "-" }}</span>
                    </div>
                    <div class="list-item">
                        <span class="list-item-title">站点名称</span>
                        <span class="list-item-value">{{ workOrderInfo.stationName || "-" }}</span>
                    </div>
                    <div class="list-item">
                        <span class="list-item-title">站点地址</span>
                        <span class="list-item-value">{{ workOrderInfo.address || "-" }}</span>
                    </div>
                    <div class="list-item">
                        <span class="list-item-title">执行人</span>
                        <span class="list-item-value">{{ workOrderInfo.solveCompanyName || "-" }}-{{ workOrderInfo.solveRbacUserName || "-" }}-{{ workOrderInfo.solveRbacUserMobile || '-' }}</span>
                    </div>
                    <div class="list-item">
                        <span class="list-item-title">状态</span>
                        <span class="list-item-value">{{ workOrderInfo.status | initDic(statusDic) }}</span>
                    </div>
                    <div class="list-item">
                        <span class="list-item-title">工单类型</span>
                        <span class="list-item-value">{{ workOrderInfo.errorType | initDic(errorTypeDic) }}</span>
                    </div>
                    <div class="list-item">
                        <span class="list-item-title">站点ID</span>
                        <span class="list-item-value">{{ workOrderInfo.stationId || "-" }}</span>
                    </div>
                    <div class="list-item">
                        <span class="list-item-title">发布人</span>
                        <span class="list-item-value">{{ workOrderInfo.sendCompanyName || "-" }}-{{ workOrderInfo.sendRbacUserName || "-" }}-{{ workOrderInfo.sendRbacUserMobile || '-' }}</span>
                    </div>
                    <div class="list-item">
                        <span class="list-item-title">截止时间</span>
                        <span class="list-item-value">{{ workOrderInfo.endTime || "-" }}</span>
                    </div>
                    <div class="list-item">
                        <span class="list-item-title">关联巡检</span>
                        <span class="list-item-value">{{ workOrderInfo.patrolSn || "-" }}</span>
                    </div>
                </div>
            </div>
            <div class="a-w-100 a-mt-10 a-flex-1 main-card">
                <div class="a-flex-rfsfs">
                    <div>
                        <div class="a-fw-700 a-fs-14 a-c-blue" style="padding-bottom: 24px">工单进度</div>
                        <el-timeline style="width: 280px;padding-left:0 !important">
                            <el-timeline-item
                                color="#B9B9B9"
                                v-for="(item, index) in tableData"
                                :key="index"
                                :timestamp="item.operateTime">
                                <div class="a-flex-cfsfs">
                                    <div class="a-flex-rfsc" style="padding-bottom: 12px">
                                        <span class="a-c-blue a-fw-700" style="padding-right: 10px" v-if='item.newStatus != item.oldStatus'>{{ item.newStatus | initDic(statusDic) }}</span>
                                        <span>{{ item.operateType | initDic(workOrderOperateTypeDic) }}</span>
                                    </div>
                                    <div class="a-fs-12 a-flex-cfsfs">
                                        <span v-if="item.operateType == 1">发布人：{{ item.operateUserName || "-" }} {{ item.operateUserMobile || "-" }}</span>
                                        <span v-if="item.operateType == 1">执行人：{{ item.changeSolveUserName || "-" }} {{ item.changeSolveUserMobile || "-" }}</span>
                                        <span v-if="item.operateType == 2">操作人：{{ item.operateUserName || "-" }} {{ item.operateUserMobile || "-" }}</span>
                                        <span v-if="item.operateType == 3">操作人：{{ item.operateUserName || "-" }} {{ item.operateUserMobile || "-" }}</span>
                                        <span v-if="item.operateType == 3">变更后：{{ item.changeSolveUserName || "-" }} {{ item.changeSolveUserMobile || "-" }}</span>
                                        <span v-if="item.operateType == 7">操作人：{{ item.operateUserName || "-" }} {{ item.operateUserMobile || "-" }}</span>
                                        <span v-if="item.operateType == 7">变更后：{{ item.changeEndTime || "-" }}</span>
                                        <!-- <span v-if="item.operateType == 2">操作人：{{ item.operateUserMobile || "-" }}</span> -->
                                        <span v-if="item.operateType == 5">操作人：{{ item.operateUserName || "-" }} {{ item.operateUserMobile || "-" }}</span>
                                        <span v-if="item.operateType == 6">操作人：{{ item.operateUserName || "-" }} {{ item.operateUserMobile || "-" }}</span>
                                        <span v-if="item.operateType == 8">执行人：{{ item.operateUserName || "-" }} {{ item.operateUserMobile || "-" }}</span>
                                        <span v-if="item.operateType == 10">操作人：{{ item.operateUserName || "-" }} {{ item.operateUserMobile || "-" }}</span>
                                        <span v-if="item.operateType == 10">变更后：{{ item.changeImportLevel | initDic(importanceLevelDic) }}</span>
                                        <span v-if="item.remark" style="word-break: break-all;">操作备注：{{ item.remark }}</span>
                                    </div>
                                </div>
                            </el-timeline-item>
                        </el-timeline>
                        <div style="width: 100%;height:100%" class="a-flex-rcc" v-if="!tableData.length">
                            <el-empty description="暂无记录"></el-empty>
                        </div>
                    </div>
                    <div class="a-flex-1 a-ml-20">
                        <div class="workOrder-content a-flex-cfsfs">
                            <span class="workOrder-content-title">工单内容</span>
                            <div class="a-ptb-12" v-for="(item,index) in workOrderContent" :key="index" style="border-bottom: 1px dashed #999999">
                                <span class="workOrder-content-text" style="word-break: break-all">问题描述：{{ item.content }}</span>
                                <div class="a-flex-rfsfs a-flex-wrap a-pb-10" v-if="item.pictureList && item.pictureList.length">
                                    <el-image 
                                        v-for="(ite,idx) in item.pictureList"
                                        :key="idx"
                                        style="width: 80px; height: 80px; border-radius: 8px; margin: 10px 10px 0 0"
                                        :src="ite" 
                                        :preview-src-list="item.pictureList">
                                    </el-image>
                                </div>
                                <div class="a-flex-rfec a-fs-14 a-c-333">
                                    <span class="a-plr-20">{{ item.operateUserMobile }}</span>
                                    <span>{{ item.operateTime }}</span>
                                </div>
                            </div>
                            <div style="width: 100%;height:100%" class="a-flex-rcc" v-if="!workOrderContent.length">
                                <el-empty :image-size='100' description="暂无记录"></el-empty>
                            </div>
                        </div>
                        <div class="workOrder-content a-flex-cfsfs" v-if="workOrderInspectionInfo && workOrderInspectionInfo.length">
                            <inspection-feedback title="巡检反馈" :content='workOrderInspectionInfo'></inspection-feedback>
                        </div>
                        <div class="workOrder-content a-flex-cfsfs" v-if="workOrderInfo">
                            <span class="workOrder-content-title">工单处理</span>
                            <div class="a-flex-cfsfs" v-if="workOrderInfo.content">
                                <!-- 问题检查 -->
                                <div class="a-flex-rfsfs a-flex-wrap">
                                    <div class="list-item">
                                        <span class="list-item-title">处理类型</span>
                                        <span class="list-item-value">{{ workOrderInfo.solveType | initDic(solveTypeDic) }}</span>
                                    </div>
                                    <div class="list-item">
                                        <span class="list-item-title">审批编号</span>
                                        <span class="list-item-value">{{ workOrderInfo.auditSn || "-" }}</span>
                                    </div>
                                </div>
                                <!-- 问题检查 -->
                                <span class="a-fs-13 a-c-blue a-fw-700 a-pb-10">问题检查</span>
                                <span class="workOrder-content-text">{{ workOrderInfo.content || "-" }}</span>
                                <div class="a-flex-rfsfs a-flex-wrap a-pb-10">
                                    <el-image 
                                        v-for="(ite,idx) in workOrderInfo.images"
                                        :key="idx"
                                        style="width: 80px; height: 80px; border-radius: 8px; margin: 10px 10px 0 0"
                                        :src="ite" 
                                        :preview-src-list="workOrderInfo.images">
                                    </el-image>
                                </div>
                                <!-- 处理结果 -->
                                <span class="a-fs-13 a-c-blue a-fw-700 a-pb-10" v-if="workOrderInfo.solveContent || workOrderInfo.solveImages.length">处理结果</span>
                                <span class="workOrder-content-text">{{ workOrderInfo.solveContent || "" }}</span>
                                <div class="a-flex-rfsfs a-flex-wrap a-pb-10">
                                    <el-image 
                                        v-for="(ite,idx) in workOrderInfo.solveImages"
                                        :key="idx"
                                        style="width: 80px; height: 80px; border-radius: 8px; margin: 10px 10px 0 0"
                                        :src="ite" 
                                        :preview-src-list="workOrderInfo.solveImages">
                                    </el-image>
                                </div>
                            </div>
                            <!-- <div class="a-flex-rfec a-fs-14 a-c-333">
                                <span class="a-plr-20">15708415426</span>
                                <span>2024-05-28 12:48:00</span>
                            </div> -->
                            <div class="a-ptb-10 a-fs-14" v-if="workOrderInfo.oldDeviceIds && workOrderInfo.oldDeviceIds.length">
                                <span class="a-fw-700">拆除设备/更换设备</span>
                            </div>
                            <el-table 
                                v-if="workOrderInfo.oldDeviceIds && workOrderInfo.oldDeviceIds.length"
                                :border='true' 
                                :data="workOrderInfo.oldDeviceIds" 
                                style="width: 100%;" >
                                <el-table-column prop="orderId" label="编号" min-width="120">
                                    <template slot-scope="{ row }">
                                        <span>{{ row.code || "" }}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="orderId" label="图片" min-width="120">
                                    <template slot-scope="{ row }">
                                        <el-image 
                                            style="width: 80px; height: 80px; border-radius: 8px; margin: 10px 10px 0 0"
                                            :src="row.image" 
                                            :preview-src-list="[row.image]">
                                        </el-image>
                                    </template>
                                </el-table-column>
                            </el-table>
                            <div class="a-ptb-10 a-fs-14" v-if="workOrderInfo.newDeviceIds && workOrderInfo.newDeviceIds.length">
                                <span class="a-fw-700">新设备</span>
                            </div>
                            <el-table 
                                v-if="workOrderInfo.newDeviceIds && workOrderInfo.newDeviceIds.length"
                                :border='true' 
                                :data="workOrderInfo.newDeviceIds" 
                                style="width: 100%;" >
                                <el-table-column prop="orderId" label="编号" min-width="120">
                                    <template slot-scope="{ row }">
                                        <span>{{ row.code || "" }}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="orderId" label="图片" min-width="120">
                                    <template slot-scope="{ row }">
                                        <el-image 
                                            style="width: 80px; height: 80px; border-radius: 8px; margin: 10px 10px 0 0"
                                            :src="row.image" 
                                            :preview-src-list="[row.image]">
                                        </el-image>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <change-executor ref="changeExecutor" @handlerSuccess='handlerChangeExecutorSuccess' :tableDataItem='currentDatas' type='workOrder'></change-executor>
        <change-deadline ref="changeDeadline" @handlerSuccess='handlerChangeDeadlineSuccess' :tableDataItem='currentDatas'></change-deadline>
        <add-desc ref="addDesc" @handlerSuccess='handlerAddDescSuccess' :tableDataItem='currentDatas'></add-desc>
        <change-import-level ref="importLevel" @handlerSuccess='handlerChangeImportantSuccess' :tableDataItem='currentDatas'></change-import-level>
    </div>
</template>
<script>
import {
    mapState,
    mapActions
} from 'vuex';
import util from '../../utils/util'
import InspectionFeedback from './child/inspection-feedback.vue';
import AddDesc from './child/add-desc.vue';
import ChangeDeadline from './child/change-deadline.vue';
import changeExecutor from './child/change-executor.vue';
import ChangeImportLevel from './child/change-importLevel.vue';
export default {
    components: {
        InspectionFeedback,
        changeExecutor, 
        ChangeDeadline, 
        AddDesc, 
        ChangeImportLevel
    },
    data() {
        return {
            util: util,
            id: '',
            workOrderInfo: '',
            importanceLevelDic: [],
            errorTypeDic: [],
            solveTypeDic: [],
            sendTypeDic: [],
            stationTypeDic: [],
            statusDic: [],
            workOrderOperateTypeDic: [],
            tableData: [],
            workOrderContent: [],
            workOrderInspectionInfo: [],
            currentDatas: '',//当前操作的列表项
        }
    },
    mounted() {
        this.$getDic('importLevel','select').then(res=>{ this.importanceLevelDic = res; })
        this.$getDic('workOrderSolveType','select').then(res=>{ this.solveTypeDic = res; })
        this.$getDic('workOrderSendType','select').then(res=>{ this.sendTypeDic = res; })
        this.$getDic('stationType','select').then(res=>{ this.stationTypeDic = res; })
        this.$getDic('workOrderStatus','select').then(res=>{ this.statusDic = res; })
        this.$getDic('workOrderOperateType','select').then(res=>{ this.workOrderOperateTypeDic = res; })
        this.getErrorTypeDic()
        this.id = this.$route.query.id
        if(this.id) {
            this.getDetails()
            this.getWorkOrderHis()
            this.getWorkOrderContent()
            this.getWorkOrderInspectionInfo()
        }
    },
    computed:{
        ...mapState({
            company: state => state.company.company,
            userInfo: state => state.user.user,
        })
    },
    methods: {
        getErrorTypeDic () {
            this.$Axios._post({
                url: this.$Config.apiUrl.getWorkOrderErrorType,
                method: "post",
                params: {},
            })
            .then(({ data })=>{
                this.errorTypeDic = data.map(item=>{
                    return {
                        label: item.name,
                        value: item.id
                    }
                })
            })
            .catch(err=>{

            })
        },
        getDetails () {
            this.$Axios._post({
                url: this.$Config.apiUrl.getWorkOrderDetail,
                method: "post",
                params: {
                    id: this.id
                },
            })
            .then((res)=>{
                this.workOrderInfo = {
                    ...res.result.data,
                    images: res.result.data.images ? JSON.parse(res.result.data.images) : [],
                    solveImages: res.result.data.solveImages ? JSON.parse(res.result.data.solveImages) : [],
                    oldDeviceIds: res.result.data.oldDeviceIds ? res.result.data.oldDeviceIds : [],
                    newDeviceIds: res.result.data.newDeviceIds ? res.result.data.newDeviceIds : [],
                }
            })
            .catch(err=>{

            })
        },
        // 获取工单进度
        getWorkOrderHis () {
            this.$Axios._post({
                url: this.$Config.apiUrl.getWorkOrderOperateHistory,
                method: "post",
                params: {
                    id: this.id
                },
            })
            .then((res)=>{
                this.tableData = res.result.data
            })
            .catch(err=>{

            })
        },
        // 获取工单内容
        getWorkOrderContent () {
            this.$Axios._post({
                url: this.$Config.apiUrl.getWorkOrderContent,
                params: {
                    id: this.id
                },
            })
            .then(res => {
                if(res.result.code == 0){
                    this.workOrderContent = res.result.data.map(item=>{
                        return {
                            ...item,
                            pictureList: item.pictureList ? JSON.parse(item.pictureList) : []
                        }
                    })
                    console.log(this.workOrderContent,'workOrderContent');
                }
            })
            .catch((error) => {
                this.$message.error(error.result.message)
            });
        },
        // 巡检信息
        getWorkOrderInspectionInfo () {
            this.$Axios._post({
                url: this.$Config.apiUrl.getQuestionAnswerItem,
                params: {
                    id: this.id
                },
            })
            .then(res => {
                if(res.result.code == 0){
                    
                    if(res.result.data.length) {
                        this.workOrderInspectionInfo = res.result.data.map(item=>{
                            let selectTableData = []
                            let selectTableDataHead = []
                            let checkImages = []
                            if(item.questionAnswerVos) {
                                selectTableData = item.questionAnswerVos.map(ite=>{
                                    let listItem = ite.questionAnswerItemVos
                                    return listItem
                                })
                                selectTableData = selectTableData[0]
                                selectTableDataHead = selectTableData.map(ite=>{
                                    return ite.question
                                })
                            }
                            try {
                                if(item.checkImages) {
                                    checkImages = JSON.parse(item.checkImages)
                                }
                            } catch (error) {
                                
                            }
                            return {
                                ...item,
                                checkImages: checkImages,
                                selectTableData: selectTableData,
                                selectTableDataHead: selectTableDataHead
                            }
                        })
                    }
                    
                }
            })
            .catch((error) => {
                this.$message.error(error.result.message)
            });
        },
        // 修改执行人
        handlerChangeExecutorSuccess (datas) {
            let reqData = {
                id: this.currentDatas.workOrderId,
                ...datas,
                operateType: 3, 
            }
            this.handleSubmitOperate(reqData)
        },
        // 修改截止时间
        handlerChangeDeadlineSuccess (datas) {
            let reqData = {
                id: this.currentDatas.workOrderId,
                ...datas,
                operateType: 7, 
            }
            this.handleSubmitOperate(reqData)
        },
        // 添加描述
        handlerAddDescSuccess (datas) {
            let reqData = {
                id: this.currentDatas.workOrderId,
                operateType: 2, 
                ...datas
            }
            this.handleSubmitOperate(reqData)
        },
        handlerChangeImportantSuccess (datas) {
            let reqData = {
                id: this.currentDatas.workOrderId,
                ...datas,
                operateType: 10, 
            }
            this.handleSubmitOperate(reqData)
        },
        // 更多操作
        handlerOperation (commands) {
            let command = commands
            let reqData
            switch (command) {
                case 'gq':
                    // 挂起
                    this.$prompt('请输入备注', '是否确认挂起？', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                    }).then(({ value })=>{
                        reqData = {
                            id: this.id,
                            operateType: 5, 
                            remark: value
                        }
                        this.handleSubmitOperate(reqData)
                    }).catch(_=>{})
                    break;
                case 'jcgq':
                    // 解除挂起
                    this.$prompt('请输入备注', '是否确认解除挂起？', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                    }).then(({ value })=>{
                        reqData = {
                            id: this.id,
                            operateType: 6, 
                            remark: value
                        }
                        this.handleSubmitOperate(reqData)
                    }).catch(_=>{})
                    break;
                case 'bgzxr':
                    // 变更执行人
                    this.currentDatas = {
                        ...this.workOrderInfo,
                        solveName: this.workOrderInfo.solveRbacUserName,
                        solveMobile: this.workOrderInfo.solveRbacUserMobile,
                    }
                    this.$refs['changeExecutor'].dialogVisible = true
                    break;
                case 'yq':
                    // 延期
                    this.currentDatas = this.workOrderInfo
                    this.$refs['changeDeadline'].dialogVisible = true
                    break;
                case 'tjms':
                    // 添加描述
                    this.currentDatas = {
                        ...this.workOrderInfo,
                        id: this.workOrderInfo.workOrderId
                    }
                    this.$nextTick(()=>{
                        this.$refs['addDesc'].getDescList()
                        this.$refs['addDesc'].dialogVisible = true
                    })
                    break;
                case 'zycd':
                    // 修改重要程度
                    this.currentDatas = this.workOrderInfo
                    this.$refs['importLevel'].dialogVisible = true
                    break;
                case 'wcgd':
                    // 直接完成
                    this.currentDatas = this.workOrderInfo
                    this.$confirm('是否确认直接完成工单?', '温馨提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                    }).then(_=>{
                        this.$Axios._post({
                            url: this.$Config.apiUrl.getWorkOrderDetail,
                            method: "post",
                            params: {
                                id: this.currentDatas.workOrderId
                            },
                        })
                        .then(({data})=>{
                            let reqData = {
                                commit: 1,
                                solveType: data.solveType,
                                auditSn: data.auditSn,
                                content: data.content,
                                images: data.images,
                                solveContent: data.solveContent,
                                solveImages: data.solveImages,
                                workOrderId: this.currentDatas.workOrderId,
                                newDeviceIds: data.newDeviceIds,
                                oldDeviceIds: data.oldDeviceIds
                            }
                            this.commitWorkOrder(reqData)
                        })
                        .catch(err=>{

                        })
                    }).catch(err=>{
                        console.log(err);
                    })
                    break;
                default:
                    break;
            }
        },
        handleSubmitOperate (reqData) {
            this.$Axios._post({
                url: this.$Config.apiUrl.operateWorkOrder,
                method: "post",
                params: {
                    ...reqData
                },
            })
            .then((res)=>{
                this.currentDatas = ''
                this.getDetails()
                this.getWorkOrderHis()
                this.getWorkOrderContent()
                this.getWorkOrderInspectionInfo()
            })
            .catch(err=>{

            })
        },
        commitWorkOrder (reqData) {
            this.$Axios._post({
                url: this.$Config.apiUrl.commitWorkOrder,
                method: "post",
                params: {
                    ...reqData
                },
            })
            .then((res)=>{
                this.currentDatas = ''
                this.getDetails()
                this.getWorkOrderHis()
                this.getWorkOrderContent()
                this.getWorkOrderInspectionInfo()
            })
            .catch(err=>{

            })
        },
    }
}
</script>
<style lang="scss" scoped>
.main {
    height: 100%;
    overflow: auto;
    .main-card{
        border-radius: 4px;
        // border: 1px solid #EBEEF5;
        background-color: #FFFFFF;
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
        padding: 20px;
    }
    .list-item{
        padding: 10px 0;
        font-size: 14px;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        .list-item-title{
            width: 80px;
            text-align: left;
            color: #666666;
            display: inline-block;
        }
        .list-item-value{
            width: 240px;
            text-align: left;
            color: #333333;
            font-weight: 700;
            display: inline-block;
        }
    }
    .workOrder-content{
        border-radius: 4px;
        padding: 20px;
        background: #F2F6FC99;
        margin-bottom: 10px;
        align-items: stretch;
        .workOrder-content-title{
            font-size: 14px;
            color: #007af0;
            font-weight: 700;
            padding-bottom: 10px;
        }
        .workOrder-content-text{
            font-size: 14px;
            color: #333333;
            padding-bottom: 10px;
        }
        /deep/ .el-table__header th.el-table__cell{
            background: #ffffff !important;
        }
    }
    
}
</style>